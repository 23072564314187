.email{
    display: flex;
    padding-top: 7%;
flex-direction: column;
  justify-content: center;
  align-items: center;
    text-align: center;
}
.h3{
    font-family: sans-serif;

}

.contact1{
    
    margin-top: 5%;
    
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-size:30px;
    
}
.contact2{    

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
z-index: 0;
}




.icon{
    color: #2c5194;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 15%;
    border: none;
    background-color: transparent;
}

@media only screen and (max-width:900px) {

    .email{
        display: flex;
        padding-top: 40%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    
    }

  
    .contact2{
        margin-left: 0;
    }
    
 
    .icon{
        padding-right: 10px;
        color: #2c5194;
        border: none;
        background-color: transparent;
    }
    .contact1{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 4%;
    
    margin-left: 0;
    }
        
    }