.regcontainer{
    position: relative;
    text-align: center;
    color: white;
}
.bottom-left {
    position: absolute;
    bottom: 10%;
    left: 0;

    margin-left:2%;
    color: rgb(255, 255, 255);
    background: none;
    border: none;
    padding: 7px 55px;
    font-weight: 600;
    font-size: x-large;
    border-radius: 20px;
    background-color: rgba(23, 70, 210, 0.729)
  }
.cover{
    background-image: url("./Cover.jpg");
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-top: 6%;
    height: 90vh;
}
@media only screen and (max-width:900px) {
    .cover{
        background-image: url(cover);
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height:100vh;
        margin-top: 25%;
        

    }
    .regcontainer{
        position: relative;
        text-align: center;
    }
    .bottom-left {
        position: absolute;
        bottom: 25%;
        margin-left:1%;
                color: rgb(255, 255, 255);
        background: none;
        border: none;
        padding: 4px 15px;
        font-weight: 600;
        font-size: x-large;
        border-radius: 20px;
        background-color: rgba(23, 70, 210, 0.729)
      }
}
