.abouthead{
    display: flex;
    align-items: center;
    justify-content: center;
}
.aboutbody{
    margin-top: 150px;
}
.h3{
    font: "Lucida Console", "Courier New", monospace;
}