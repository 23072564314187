.structure{
    display: flex;
    flex-wrap: wrap;
    padding-top: 2%;
    padding-bottom: 10%;
    justify-content: center;
    
}
.card{    
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.card:hover{
    transform: scale(1.02);

    transition: 2s;

}
.structuret{
    font-size: xx-large;
    padding-top: 15%;
    
}
.structureti{
    font-size: xx-large;
    padding-top: 1%;

    
}
@media screen and (max-width:900px){
    .structure{
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 10%;
        justify-content: center;
    }.structuret{
        font-size: xx-large;
        padding-top: 40%;
    
        
    }
    .structureti{
        font-size: xx-large;
        padding-top: 10%;
    
        
    }
}