
::-webkit-scrollbar {
  height: 5px;
width: 0;
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}

html {
  scroll-snap-type: y mandatory;
}



.section {
  padding-top: 25vh;
  padding-bottom: 25vh;
  position: relative;
}



.progress {
  position: fixed;

  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
}
